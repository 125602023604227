import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
font-family: Ubuntu, sans-serif;
font-size: 11pt;
text-align: center;
height: 5vh;
`

export function Footer() {
    return(
        <FooterContainer>Se quiser fazer parte desta base de dados entre em contacto connosco através do e-mail &nbsp;
              <a href="mailto:gifcr@apfisio.pt">gifcr@apfisio.pt</a></FooterContainer>
    )
}